

function App() {

  // fetch('https://api.printify.com/v1/shops/7036259/products.json'){

  // }
  // fetch('https://api.printify.com/v1/shops/7036259/products.json', {
  //   headers: { 'Authorization': `Bearer ${'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzN2Q0YmQzMDM1ZmUxMWU5YTgwM2FiN2VlYjNjY2M5NyIsImp0aSI6IjU2Nzg0NjU1MTU5YTlhMTdkZWI2MWRjZDY2MDE5YWQ4ZjEwNmVhZDRmZDJkOGUwOWVkOTRjN2YzNjE3YzQ2MTU1NDAwYjVkZTVhMGRlY2I5IiwiaWF0IjoxNjg0MDk3NzQyLjQ5Mzg2OCwibmJmIjoxNjg0MDk3NzQyLjQ5Mzg3MiwiZXhwIjoxNzE1NzIwMTQyLjQ4NjU4OCwic3ViIjoiMTE3MjM5NzYiLCJzY29wZXMiOlsic2hvcHMubWFuYWdlIiwic2hvcHMucmVhZCIsImNhdGFsb2cucmVhZCIsIm9yZGVycy5yZWFkIiwib3JkZXJzLndyaXRlIiwicHJvZHVjdHMucmVhZCIsInByb2R1Y3RzLndyaXRlIiwid2ViaG9va3MucmVhZCIsIndlYmhvb2tzLndyaXRlIiwidXBsb2Fkcy5yZWFkIiwidXBsb2Fkcy53cml0ZSIsInByaW50X3Byb3ZpZGVycy5yZWFkIl19.AlJY4RpttmCEAAzcunVjqo-1Fh6wSjVnjNZD-__Ge5xEjwnywHXpqaaLMwjkdUk0ISZ0ebpo6XWHQwA20Q4'}` }
  // })
//     .then(result => result.json())
//     .then(result => {
// console.log(result)
//     })
console.log(process.env)
  return (
    <div className="container-fluid">
      <div className='row'>
        <img 
        src='./brc_header.png' 
        className='logo col mt-3' 
        alt=""/>
      </div>
    </div>
  );
}

export default App;
